import React, {useEffect, useState} from "react";
import "./Modal.css";
import FormikInput from "./FormikInput";
import Button from "../Buttons/Button";
import {useFormik} from "formik";
import * as Yup from "yup";
import {connect, useDispatch} from "react-redux";
import SelectWithChoices from "./SelectWithChoices";
import {RootState} from "../../redux/reducers/rootReducer";
import closeIcon from "../../img/Dashboard/Employee/close_btn.svg";
import { useTranslation } from "react-i18next";
import {
  getBranches,
  getDepartments,
  getDepartmentsAndBranches
} from "../../redux/actions/branchAndDepartmentActions";
import {updateEmployee} from "../../redux/actions/usersActions";
import {capitalize} from "../../utils/capitalizeFirstLetter";
import validatePhoneNumber from "../../utils/phoneNumberValidation";
import {getToken} from "../../utils/cookies/tokensCookie";
import PaymentAuthenticationPopup
  from "../Payments/PaymentAuthenticationPopup/PaymentAuthenticationPopup";
import PhoneNumberInput from "../Elements/PhoneNumberInput/PhoneNumberInput";
import {paymentsService} from "../../api/services/Payments/payments.service";
import {toast} from "react-toastify";
import CustomToastContainer from "./ToastContainer";

interface CustomProps {
  closeModal: (state:boolean, data:any) => void;
  selectedUserData: any;
  branches?: any;
  departments?: any;
  branchesAndDep?: any;
  company?: any;
  rootUser?: any;
}

interface IUserInformation {
  companyId: string;
  imageUrl: string;
  firstName: string;
  lastName: string;
  phoneNumber: string;
  employmentType: string;
  customIdentifier: string;
  email: string;
  approveInvoices: string;
  departmentId: string;
  branchId: string;
}

const labelStyles = {
  paddingBottom:"7px",
  paddingLeft:"7px",
  fontSize:"0.9rem"
};

function ModalEmployeeUpdate(
  {selectedUserData, closeModal,
    branches, departments, branchesAndDep, company, rootUser}:CustomProps) {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  // Options for selects
  const [branchesOptions, setBranchesOptions] = useState([]);
  const [showPaymentsAuthModal, setShowPaymentsAuthModal] = useState(false);

  // Filtered data for options
  const [filteredDepartments, setFilteredDepartments]
		= useState([]);

  const [authorisedUserPhoneNumber, setAuthorisedUserPhoneNumber] = useState("");
  const [authorisedUserPhoneNumberButtonLoading, setAuthorisedUserPhoneNumberButtonLoading] = useState(false);

  // take departments data
  useEffect(() => {
    dispatch(getDepartments());
  }, [dispatch]);

  // take branches data
  useEffect(() => {
    dispatch(getBranches());
  }, [dispatch]);

  // take branchesAndDepartments data
  useEffect(() => {
    dispatch(getDepartmentsAndBranches());
  }, [dispatch]);

  // from data to select options
  useEffect(() => {
    if (branches.branches?.length) {
      const options:any = [];
      branches.branches.forEach((item:any) => {
        options.push({
          value: item._id,
          label: item.name
        });});
      setBranchesOptions(options);
      handleBranchSelect({value: selectedUserData.branchId,
        label: selectedUserData.branch});
    }
    //Need to disable it, because eslint says, that I need to add
    //all deps that will always refresh page
    // eslint-disable-next-line
	}, [branches.branches])

  useEffect(() => {
    if (departments.departments?.length) {
      const options:any = [];
      departments.departments.forEach((item:any) => {
        options.push({
          value: item._id,
          label: item.name
        });});
      setFilteredDepartments(options);
    }
  }, [departments.departments]);

  const formik = useFormik<IUserInformation>({
    initialValues: {
      companyId: selectedUserData.companyId,
      imageUrl: selectedUserData.imageUrl || "imageUrl",
      firstName: selectedUserData.firstName,
      lastName: selectedUserData.lastName,
      phoneNumber: selectedUserData.phoneNumber,
      customIdentifier: selectedUserData.customIdentifier || "",
      employmentType: selectedUserData.employmentType || "fullTime",
      email: selectedUserData.email,
      departmentId: selectedUserData.departmentId,
      approveInvoices: selectedUserData.approveInvoices,
      branchId: selectedUserData.branchId
    },
    validationSchema: Yup.object().shape({
      firstName: Yup.string().required(t("formik_warnings.general_info.firstName")),
      lastName: Yup.string().required(t("formik_warnings.general_info.lastName")),
      email: Yup.string().required(t("formik_warnings.general_info.email"))
        .email(t("formik_warnings.general_info.emailValid")),
      phoneNumber: company.company.virtualCard
        && Yup.string()
          .test("test-name", "Error", function(value:any) {
            if (value && value.length > 0) {
              const { path, createError } = this;
              const phoneValidation = validatePhoneNumber(value);
              if (phoneValidation) {
                return true;
              } else {
                return createError({ path, message: t("formik_warnings.general_info.phoneNumber_validation") });
              }
            } else {
              return true;
            }
          })
    }),
    onSubmit: async (values) => {
      /*e?.preventDefault();*/

      if (formik.isValid) {
        const weavrToken = await getToken(true) || undefined;

        // If phone number was changed and user has a virtual card => need to change number on card

        if (selectedUserData.authorizedUserId && selectedUserData.authorizedUserId.length > 0 && !weavrToken) {
          setShowPaymentsAuthModal(true);
        } else {
          formik.values.firstName = formik.values.firstName.substr(0, 1).toUpperCase()
            + formik.values.firstName.substr(1);
          formik.values.lastName = formik.values.lastName.substr(0, 1).toUpperCase()
            + formik.values.lastName.substr(1);
          const postData = {
            companyId: formik.values.companyId,
            imageUrl: formik.values.imageUrl,
            firstName: capitalize(formik.values.firstName),
            lastName: capitalize(formik.values.lastName),
            phoneNumber: capitalize(formik.values.phoneNumber),
            customIdentifier: formik.values.customIdentifier,
            employmentType: formik.values.employmentType,
            email: formik.values.email,
            approveInvoices: formik.values.approveInvoices,
            departmentId: formik.values.departmentId,
            branchId: formik.values.branchId
          };
          let isAllowedBranchAndSelect = false;
          branchesAndDep.forEach((branAndDep: any) => {
            if (branAndDep.branchId === postData.branchId) {
              if (branAndDep.departmentId === postData.departmentId) {
                isAllowedBranchAndSelect = true;
              }
            }
          });
          if (isAllowedBranchAndSelect) {
            setButtonLoading(true);
            dispatch(updateEmployee({
              ...postData,
              paymentsToken: weavrToken,
              withVirtualCards: company?.company?.virtualCard
            }, selectedUserData._id));
            closeModal(false, [""]);
          } else {
            setBranchAndDepError(t("warnings.branch_dep_error"));
          }
        }
      }
    },
  });

  // Filtered ids for request
  const [selectedBranch, setSelectedBranch] = useState({
    value: selectedUserData.branchId,
    label: selectedUserData.departmentId
  });
  const [selectedDepartment, setSelectedDepartment] = useState({
    value: selectedUserData.departmentId,
    label: selectedUserData.department
  });

  useEffect(() => {
    formik.setFieldValue("branchId", selectedBranch.value, true);
  }, [selectedBranch]);
  useEffect(() => {
    formik.setFieldValue("departmentId", selectedDepartment.value, true);
  }, [selectedDepartment]);

  const employmentTypeOptions = [
    {
      value: "fullTime",
      label: "Vollzeit"
    },
    {
      value: "partTime",
      label: "Teilzeit"
    }
  ];

  const [branchAndDepError, setBranchAndDepError] = useState("");

  // handle actions after branch selected
  const handleBranchSelect = (selected:any) => {
    if (departments && departments.departments?.length) {
      const departmentInSelectedBranch:any = [];
      branchesAndDep.forEach((branAndDep:any) => {
        if (branAndDep.branchId === selected.value) {
          departments.departments.forEach((department:any) => {
            if (branAndDep.departmentId
							=== department._id) {
              departmentInSelectedBranch.push({
                value: department._id,
                label: department.name
              });
            }
          });
        }
      });
      // remove duplicated branches
      const uniqueBranches = departmentInSelectedBranch
        .filter((elem:any, index:number) =>
          departmentInSelectedBranch
            .findIndex((obj:any) =>
              obj.value === elem.value) === index);
      setFilteredDepartments(uniqueBranches);
      setSelectedBranch({
        value: selected.value,
        label: selected.label
      });
    }
  };

  // handle actions after DEPARTMENT selected
  const handleDepartmentSelect = (selected:any) => {
    setSelectedDepartment({
      label: selected.label,
      value: selected.value
    });
  };

  const [buttonLoading, setButtonLoading] = useState(false);

  const onChangeAuthorisedUserPhoneNumber = async () => {
    setAuthorisedUserPhoneNumberButtonLoading(true);
    const response = await new paymentsService().changeAuthorisedUserPhoneNumber({
      userId: selectedUserData.authorizedUserId,
      phoneNumber: authorisedUserPhoneNumber
    });
    if (response?.data?.message === "unauthorized") {
      setShowPaymentsAuthModal(true);
    }
    if (response.status) {
      toast.success(
        <CustomToastContainer
          message={t("dashboard.general.partner_portal_profile_success_update")}
          status={1}
        />,
        {
          autoClose: 5000,
        }
      );
      setAuthorisedUserPhoneNumberButtonLoading(false);
    } else {
      setAuthorisedUserPhoneNumberButtonLoading(false);
    }
  };

  console.log(formik.values);

  return(
    <div className="modal_container">

      <PaymentAuthenticationPopup
        showModal={showPaymentsAuthModal}
        onModalChange={(state: boolean) => setShowPaymentsAuthModal(state)}
        email={rootUser?.userInfo?.user?.email}
        onLoginSuccess={() => {
          authorisedUserPhoneNumberButtonLoading
            ? onChangeAuthorisedUserPhoneNumber()
            : formik.handleSubmit();
        }}
        onCloseModal={() => {
          setShowPaymentsAuthModal(false);
        }}
      />

      <img
        onClick={() => closeModal(false, [""])}
        className="close_popup"
        src={closeIcon} alt="close"/>
      <h2>{t("dashboard.employee.employee_modal_title")}</h2>
      <form onSubmit={formik.handleSubmit}>
        <div className="space-between_plans" style={{marginBottom:"1rem"}}>
          <div style={{width: "100%"}}>
            <p className="filter_label" style={labelStyles}>
              {t("dashboard.employee.employee_modal_label_5")}
            </p>
            <SelectWithChoices
              style={{width:"100%",paddingRight:"10px"}}
              defaultValue={{value: selectedUserData.branchId,
                label: selectedUserData.branch}}
              isMulti="no"
              name="branch"
              selectPlaceHolder=
                {t("dashboard.employee.employee_modal_label_4")}
              selectId="branches"
              options={branchesOptions}
              handleAddChoice={handleBranchSelect}
            />
          </div>
          <div style={{width: "100%"}}>
            <p className="filter_label" style={labelStyles}>
              {t("dashboard.employee.employee_modal_label_4")}
            </p>
            <SelectWithChoices
              style={{width:"100%",paddingLeft:"5px"}}
              defaultValue={{value: selectedUserData.departmentId,
                label: selectedUserData.department}}
              isMulti="no"
              name="department"
              selectPlaceHolder=
                {t("dashboard.employee.employee_modal_label_4")}
              selectId="departments"
              options={filteredDepartments}
              handleAddChoice={handleDepartmentSelect}
            />
          </div>
        </div>
        {branchAndDepError.length
          ?
          <p
            className="input_error"
            style={{marginBottom:"20px"}}>
            {branchAndDepError}
          </p>
          : null}
        <div className="update_modal_row">
          <div style={{width:"100%", position: "relative"}}>
            <FormikInput
              style={{width:"100%", marginRight:"15px"}}
              htmlFor="firstName"
              name="firstName"
              value={formik.values.firstName}
              disabled={false}
              handleChange={formik.handleChange}
              label={t("dashboard.employee.employee_modal_label_1")}
              onBlur={formik.handleBlur}
            />
            {formik.errors.firstName
						&& formik.touched.firstName
						&& <p
						  className="input_warning"
						>
						  {formik.errors.firstName}
						</p>}
          </div>
          <div style={{width:"100%", position: "relative"}}>
            <FormikInput
              style={{width:"100%"}}
              htmlFor="lastName"
              name="lastName"
              value={formik.values.lastName}
              disabled={false}
              handleChange={formik.handleChange}
              label={t("dashboard.employee.employee_modal_label_2")}
              onBlur={formik.handleBlur}
            />
            {formik.errors.lastName
						&& formik.touched.lastName
						&& <p
						  className="input_warning"
						>
						  {formik.errors.lastName}
						</p>}
          </div>
        </div>

        <div className="relative" style={{ width: "100%" }}>
          <p className="filter_label" style={labelStyles}>
            {t("dashboard.employee.employee_modal_label_6")}
          </p>
          <SelectWithChoices
            style={{ width: "100%", maxWidth: "none", marginBottom: "10px" }}
            isMulti="no"
            name="employmentType"
            defaultValue={employmentTypeOptions.filter((item: any) => item.value === formik.values.employmentType)[0] || undefined}
            onBlur={formik.handleBlur("employmentType")}
            selectPlaceHolder={t("dashboard.employee.employee_modal_label_6")}
            selectId="employmentType"
            options={employmentTypeOptions}
            handleAddChoice={(type: any) => {
              formik.setFieldValue("employmentType", type.value, true);
            }}
          />
        </div>

        <div className="update_modal_row">
          <div style={{width:"100%", position: "relative"}}>
            <FormikInput
              style={{width:"100%", marginRight:"15px"}}
              htmlFor="customIdentifier"
              name="customIdentifier"
              value={formik.values.customIdentifier}
              disabled={false}
              handleChange={formik.handleChange}
              label={t("dashboard.employee.employee_modal_label_identifier")}
              onBlur={formik.handleBlur}
            />
            {formik.errors.customIdentifier
            && formik.touched.customIdentifier
            && <p
              className="input_warning"
            >
              {formik.errors.customIdentifier}
            </p>}
          </div>
        </div>
        <div className="update_modal_row">
          <div style={{width:"100%", position: "relative"}}>
            <FormikInput
              htmlFor="email"
              name="email"
              value={formik.values.email}
              disabled={false}
              handleChange={formik.handleChange}
              label={t("dashboard.employee.employee_modal_label_3")}
              onBlur={formik.handleBlur}
            />
            {formik.errors.email
					&& formik.touched.email
					&& <p
					  className="input_warning"
					>
					  {formik.errors.email}
					</p>}
          </div>
          <div style={{width:"100%", position: "relative"}}>
            <PhoneNumberInput
              label={t("dashboard.employee.employee_modal_label_7")}
              name={"phoneNumber"}
              handleChange={(value: string) => {
                formik.setFieldValue("phoneNumber", value.replace(/ /g,""), true);
              }}
              value={formik.values.phoneNumber}
              onBlur={formik.handleBlur}
              error={formik.touched.phoneNumber && formik.errors.phoneNumber ? formik.errors.phoneNumber : undefined}
            />
          </div>
        </div>
        <div className="modal_btn_container">
          <Button
            disabled={!formik.dirty}
            buttonType="primary"
            buttonHtmlType="submit"
            buttonLabel=
              {t("dashboard.employee.employee_modal_btn_2")}
            submitButton={true}
            fullWidth={true}
            loading={buttonLoading}
          />
        </div>
      </form>

      {selectedUserData.allowChangeWeavrAuthUserPhoneNumber
        &&
        <div
          style={{marginTop: "20px", width:"100%", marginBottom: "40px"}}
        >
          <h3>Ändere die autorisierte Telefonnummer des Benutzers</h3>
          <PhoneNumberInput
            label={t("dashboard.employee.employee_modal_label_7")}
            name={"phoneNumber"}
            handleChange={(value: string) => {
              setAuthorisedUserPhoneNumber(value);
            }}
            value={authorisedUserPhoneNumber}
          />
          <Button
            disabled={!authorisedUserPhoneNumber}
            buttonType="primary"
            buttonHtmlType="submit"
            buttonLabel=
              {t("dashboard.employee.employee_modal_btn_2")}
            fullWidth={true}
            loading={authorisedUserPhoneNumberButtonLoading}
            buttonHandler={() => {
              onChangeAuthorisedUserPhoneNumber();
            }}
          />
        </div>
      }

    </div>
  );
}

const mapStateToProps = (state:RootState) => {
  return {
    branches: state.depsAndBranches.branches,
    departments: state.depsAndBranches.departments,
    branchesAndDep: state.depsAndBranches.departmentAndBranches,
    company: state.company.company,
    rootUser: state.users
  };
};

export default connect(mapStateToProps, null)(ModalEmployeeUpdate);
